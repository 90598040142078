import * as React from "react";

interface Props {
  videoId: string;
}

const YouTube = ({ videoId }: Props) => {
  return (
    <iframe
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="w-full aspect-video my-6 shadow-lg"
      loading="lazy"
    ></iframe>
  );
};

export default YouTube;
