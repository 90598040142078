import * as React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { Link } from "gatsby";

export interface GalleryFrontmatter {
  type: "gallery";
  title: string;
  images: {
    image: ImageDataLike & {
      publicURL: string;
    };
    alt?: string;
  }[];
}

interface Props {
  frontmatter: GalleryFrontmatter;
}

const Gallery = ({ frontmatter: { title, images } }: Props) => {
  return (
    <div className="container xl:max-w-screen-xl mt-8 md:mt-16 mb-40">
      <div className="prose max-w-none">
        <h1>{title}</h1>
      </div>

      <ul className="list-none columns-1 md:columns-2 xl:columns-3 mt-8">
        {images.map(({ image, alt }) => {
          const imageData = getImage(image);

          if (!imageData) {
            return null;
          }

          return (
            <li className="mb-4">
              <Link to={image.publicURL}>
                <GatsbyImage image={imageData} alt={alt || ""} />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Gallery;
