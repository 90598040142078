import * as React from "react";
import MDXWrapper from "../mdx/MDXWrapper"

export interface ColumnsFrontmatter {
  type: "columns";
  title: string;
}

interface ColumnProps {
  children: React.ReactNode;
}

const Column = ({ children }: ColumnProps) => (
  <div className="flex-1 prose xl:prose-xl">{children}</div>
);

interface Props {
  frontmatter: ColumnsFrontmatter;
  children: React.ReactNode;
}

const Columns = ({ frontmatter: { title }, children }: Props) => {
  return (
    <div className="container xl:max-w-screen-xl mt-8 md:mt-16 mb-40">
      <article className=" prose xl:prose-xl prose-stone max-w-none">
        <h1>{title}</h1>
        <div className="md:flex gap-8 ">
          <MDXWrapper components={{ Column }}>{children}</MDXWrapper>
        </div>
      </article>
    </div>
  );
};

export default Columns;
