import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import * as React from "react";

export interface FullScreenImageFrontmatter {
  type: "full_screen_image";
  title: string;
  image: ImageDataLike;
  alt: string;
}

interface Props {
  frontmatter: FullScreenImageFrontmatter;
}

const FullScreenImage = (props: Props) => {
  const {
    frontmatter: { image, alt },
  } = props;

  const imageData = getImage(image);

  return imageData ? (
    <GatsbyImage
      image={imageData}
      alt={alt}
      className="object-fill h-screen w-full"
    ></GatsbyImage>
  ) : null;
};

export default FullScreenImage;
