import { MDXProvider, MDXProviderComponentsProp } from "@mdx-js/react";
import * as React from "react";
import YouTube from "./YouTube";

interface Props {
    children: React.ReactNode;
    components?: MDXProviderComponentsProp
}

const MDXWrapper = ({ children, components }: Props) => (
  <MDXProvider components={{ YouTube, ...components }}>{children}</MDXProvider>
);

export default MDXWrapper;
