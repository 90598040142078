import * as React from "react";
import Header from "./Header";
import { Helmet } from "react-helmet";
import PageContents, { PageFrontmatter } from "./pageTypes";
import { graphql, useStaticQuery } from "gatsby"

interface Props {
  children: React.ReactNode;
  frontmatter: PageFrontmatter;
  slug: string;
  title: string;
}

const Layout = (props: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  
  const { children, frontmatter, slug, title } = props;
  const pageTitle = `${slug === "" ? "" : `${frontmatter.title} —`} ${title}`;
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en-US" }}>
        <title>{pageTitle}</title>
        <meta name="og:title" content={pageTitle} />
        <meta name="og:type" content="website" />
      </Helmet>
      <Header slug={slug} />
      <PageContents frontmatter={frontmatter}>{children}</PageContents>
    </>
  );
};

export default Layout;
