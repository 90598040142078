import { Link } from "gatsby";
import * as React from "react";

interface Props {
  to: string;
  active: boolean;
  children: React.ReactNode;
}

const NavLink = ({ to, active, children }: Props) => (
  <Link
    to={to}
    className={`p-2 ${
      active ? "text-stone-700" : "text-stone-400"
    } hover:text-stone-900 underline underline-offset-8 transition-all decoration-transparent hover:decoration-current`}
  >
    {children}
  </Link>
);

export default NavLink;
