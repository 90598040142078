import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";
import NavLink from "../components/NavLink";
import { MainMenuQuery } from "../../graphql-types";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";

interface Props {
  slug: string;
}

const Header = ({ slug }: Props) => {
  const { navigationYaml } = useStaticQuery<MainMenuQuery>(graphql`
    query MainMenu {
      navigationYaml {
        links {
          slug
          title
        }
      }
    }
  `);

  return (
    <header className="px-6 py-4 md:py-6 bg-stone-50/90 backdrop-blur-md fixed bottom-0 w-full border-t z-10">
      <Popover>
        <Popover.Button className="inline-block align-sub mr-2 p-1 md:hidden">
          <span className="sr-only">Open menu</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </Popover.Button>
        <div className="inline-block">
          <h1 className="font-bold tracking-widest uppercase italic mr-2 mb-2">
            <Link
              to="/"
              className="p-2 text-xl text-stone-700 hover:text-stone-900 transition"
            >
              GEIL Comedy
            </Link>
          </h1>
        </div>
        <nav className="hidden md:inline-block">
          <ul className="list-none">
            {navigationYaml?.links?.map((link) =>
              link?.slug ? (
                <li className="inline-block mr-2 mb-2" key={link.slug}>
                  <NavLink
                    to={`/${link.slug}`}
                    active={slug === link.slug}
                  >
                    {link?.title}
                  </NavLink>
                </li>
              ) : null
            )}
          </ul>
        </nav>
        <nav className="md:hidden">
          <Transition
            as={React.Fragment}
            enter="transition-all"
            leave="transition-all"
            enterFrom="max-h-0 opacity-0"
            leaveTo="max-h-0 opacity-0"
            enterTo="max-h-80 opacity-100"
            leaveFrom="max-h-80 opacity-100"
          >
            <Popover.Panel>
              <ul className="list-none">
                {navigationYaml?.links?.map((link) =>
                  link?.slug ? (
                    <li className="mb-2" key={link.slug}>
                      <NavLink
                        to={`/${link.slug}`}
                        active={slug === link.slug}
                      >
                        {link?.title}
                      </NavLink>
                    </li>
                  ) : null
                )}
              </ul>
            </Popover.Panel>
          </Transition>
        </nav>
      </Popover>
    </header>
  );
};

export default Header;
