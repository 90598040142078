import React from "react";
import Default, { DefaultFrontmatter } from "./Default";
import FullScreenImage, { FullScreenImageFrontmatter } from "./FullScreenImage";
import Columns, { ColumnsFrontmatter } from "./Columns";
import Gallery, { GalleryFrontmatter } from "./Gallery";

export type PageFrontmatter =
  | DefaultFrontmatter
  | FullScreenImageFrontmatter
  | ColumnsFrontmatter
  | GalleryFrontmatter;

interface Props {
  children: React.ReactNode;
  frontmatter: PageFrontmatter;
}

const PageContents = ({ children, frontmatter }: Props) =>
  frontmatter.type === "full_screen_image" ? (
    <FullScreenImage frontmatter={frontmatter} />
  ) : frontmatter.type === "columns" ? (
    <Columns frontmatter={frontmatter}>{children}</Columns>
  ) : frontmatter.type === "gallery" ? (
    <Gallery frontmatter={frontmatter} />
  ) : (
    <Default frontmatter={frontmatter}>{children}</Default>
  );

export default PageContents;
