import React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/Layout";
import { PageQuery } from "../../graphql-types";
import { PageFrontmatter } from "../components/pageTypes";
import { MDXRenderer } from "gatsby-plugin-mdx";

type DataType = PageQuery & {
  mdx: {
    frontmatter: PageFrontmatter;
  };
};

const Page = (props: PageProps<DataType>) => {
  const {
    data: {
      mdx: { frontmatter, body, slug },
      site,
    },
  } = props;

  return (
    <Layout
      title={site?.siteMetadata?.title || ""}
      slug={slug || ""}
      frontmatter={frontmatter}
    >
      <MDXRenderer>{body}</MDXRenderer>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query Page($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        type
        title
        displayTitle
        includeContactForm
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        linkBlocks {
          title
          url
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        images {
          alt
          image {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      id
      slug
      body
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
