import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import * as React from "react";
import MDXWrapper from "../mdx/MDXWrapper";
import { Link } from "gatsby";

export interface DefaultFrontmatter {
  type: "default";
  title: string;
  displayTitle?: string;
  includeContactForm: boolean;
  linkBlocks?: {
    title: string;
    url: string;
    image: ImageDataLike;
  }[];
}

interface Props {
  frontmatter: DefaultFrontmatter;
  children: React.ReactNode;
}

const Default = ({
  frontmatter: { title, displayTitle, linkBlocks, includeContactForm },
  children,
}: Props) => {
  return (
    <div className="mx-8 mt-8 md:mt-16 mb-40">
      <article className="prose xl:prose-xl prose-stone mx-auto">
        <h1>{displayTitle || title}</h1>

        {linkBlocks ? (
          <div className="not-prose">
            <ul className="list-none grid grid-cols-1 md:grid-cols-2 gap-4">
              {linkBlocks.map(({ image, url, title }, i) => {
                const imageData = getImage(image);

                return (
                  <li key={i}>
                    <Link to={url} className="group">
                      <div>
                        {imageData ? (
                          <GatsbyImage
                            image={imageData}
                            alt={title}
                            className="aspect-video"
                          />
                        ) : null}
                        <div className="text-center text-sm text-stone-700 font-light mt-2 group-hover:underline">
                          {title}
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}

        <MDXWrapper>{children}</MDXWrapper>

        {includeContactForm ? (
          <form method="post" netlify-honeypot="bot-field" name="contact" data-netlify>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>
                Email
                <input
                  type="email"
                  name="email"
                  className="mt-1 block w-full rounded-md bg-stone-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                />
              </label>
            </p>
            <p>
              <label>
                Message
                <textarea
                  name="message"
                  className="mt-1 block w-full rounded-md bg-stone-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                ></textarea>
              </label>
            </p>
            <p>
              <button
                type="submit"
                className="bg-stone-700 hover:bg-stone-900 text-white uppercase py-2 px-8 rounded-md tracking-widest"
              >
                Send
              </button>
            </p>
          </form>
        ) : null}
      </article>
    </div>
  );
};

export default Default;
